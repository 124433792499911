body {
  margin: 0;
  font-family: halyard-display, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5rem;
  font-size: 1rem;
  background-size: 100% 1000px;
  background-image: linear-gradient(
    0deg,
    #fdfa33 3.13%,
    #fc5fac 3.13%,
    #fc5fac 6.25%,
    #49da62 6.25%,
    #49da62 9.38%,
    #ffffff 9.38%,
    #ffffff 12.5%,
    #fee93a 12.5%,
    #fee93a 15.63%,
    #059fb0 15.63%,
    #059fb0 18.75%,
    #ff6a0d 18.75%,
    #ff6a0d 21.88%,
    #9fb3c3 21.88%,
    #9fb3c3 25%,
    #fcebc3 25%,
    #fcebc3 28.13%,
    #2b436e 28.13%,
    #2b436e 31.25%,
    #f9081d 31.25%,
    #f9081d 34.38%,
    #ed258a 34.38%,
    #ed258a 37.5%,
    #f4651a 37.5%,
    #f4651a 40.63%,
    #a7e9ff 40.63%,
    #a7e9ff 43.75%,
    #fb62a1 43.75%,
    #fb62a1 46.88%,
    #fff 46.88%,
    #fff 50%,
    #fdfa33 50%,
    #fdfa33 53.13%,
    #fc5fac 53.13%,
    #fc5fac 56.25%,
    #49da62 56.25%,
    #49da62 59.38%,
    #ffffff 59.38%,
    #ffffff 62.5%,
    #fee93a 62.5%,
    #fee93a 65.63%,
    #059fb0 65.63%,
    #059fb0 68.75%,
    #ff6a0d 68.75%,
    #ff6a0d 71.88%,
    #9fb3c3 71.88%,
    #9fb3c3 75%,
    #fcebc3 75%,
    #fcebc3 78.13%,
    #2b436e 78.13%,
    #2b436e 81.25%,
    #f9081d 81.25%,
    #f9081d 84.38%,
    #ed258a 84.38%,
    #ed258a 87.5%,
    #f4651a 87.5%,
    #f4651a 90.63%,
    #a7e9ff 90.63%,
    #a7e9ff 93.75%,
    #fb62a1 93.75%,
    #fb62a1 96.88%,
    #fff 96.88%,
    #fff 100%
  );
}
h1,
h2,
h3,
h4 {
  margin: 2rem 0 0 0;
  padding: 0;
}
h1 {
  margin-bottom: 3rem;
}
h1,
h2 {
  font-size: 4rem;
  font-family: "Amatic SC";
  font-weight: 900;
  color: #4ab253;
}
h2 {
  color: #2b2b2b;
  font-size: 2rem;
}
h3 {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img {
  max-width: 100%;
}

a:link,
a:visited {
  color: #2ea3f2;
  text-decoration: none;
}
